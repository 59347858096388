export default class Strings{
    public static HOME_BANNER_TITLE = "Maximizing Entrepreneurial Value Through Technology Innovation"
    public static PROPOSITION_BANNER_TITLE ="Ingenia is an emerging tech engineering studio focused on empowering entrepreneurs to solve real problems and accelerate growth. We help entrepreneurs excel through:"
    public static TEAM_BANNER_TITLE ="Our core values guide our behavior and act as a foundation for all our decisions."
    public static OURVALUE_BANNER_TITLE ="Our Value Proposition"
    public static CONTACT_TITLE ="Interested in learning more?" 
    public static CONTACT_TITLE_SUB ="Get in touch with us" 
    public static TEAM_SUB ="Leadership team with deep startup & enterprise expertise" 
    
    public static TEAM_1 ="ISAAC BALBIN" 
    public static TEAM_2 ="Ralf Kaiser" 
    public static TEAM_3 ="John Corpus" 
    public static TEAM_4 ="Abnesh Raina"    

  }
  