import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import "./propositionGrid.scss";
interface Iprops {
  subHead: string;
  subPrea: string;
  gridWidth: string | number;
  blockchain?: string;
  compaines?: string;
}
const PropositionGrid = (props: Iprops) => {
  return (
    <Box
      className="propositionSectionGrid"
      sx={{
        display: "flex",
        margin: "0",
        color: "#FFF",
        flexDirection: {
          xs: "column",
          sm: "row",
        },
        justifyContent: "space-between",
        width: "calc(33% - 100px)",
      }}
    >
      <Box width={props.gridWidth}>
        <Box>
          <Typography className="subHead">{props.subHead}</Typography>
        </Box>
        <Box sx={{ borderBottom: "2px solid #000", minHeight:"12vh" }}>
          <Typography className="subPrea">
            {props.subPrea}{" "}
            <span style={{ fontWeight: "bold" }}>{props.blockchain}</span>{" "}
            <span style={{ fontWeight: "normal" }}> {props.compaines}</span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default PropositionGrid;
